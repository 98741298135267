import React from 'react';
import './App.css';
import FoodForm from './FoodForm';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

function App() {
  return (
    <Router>
<div className="App-wrapper">

      <div className="App">
        <header className="App-header">


          <Routes>
            <Route path="/" element={<FoodForm />} />
          </Routes>
        </header>
      </div>
      </div>
    </Router>
  );
}

export default App;
